<template>
  <div>
    <v-row align="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.push({path: '/anagrafiche/parcoBinari'})" title="Torna a Gestione Parco Binari">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  <TosListVuetify :entityName="entityName" :headers="headers" :defaultFilters="defaultFilters" :prepareItemFunction="prepareItem">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
           <v-col cols="6">
           <v-text-field  :label="tLabel('parco_binari')" v-model="nomeParcoBinari" :readonly="true" />
          </v-col>
          <v-col cols="6">
            <v-text-field  :label="tLabel('binari_numero')" v-model.number="slotProps.data.numero" />
          </v-col>      

        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="2">
              <v-text-field :label="tLabel('binari_numero') + '*'" v-model.number="slotProps.data.numero" :rules="[rules.required]" :error-messages="slotProps.errors.numero" />
            </v-col>
            <v-col cols="10">
              <v-text-field :label="tLabel('descrizione')" v-model="slotProps.data.descrizione" :error-messages="slotProps.errors.descrizione" />
            </v-col>
        </v-row>
        <v-row>
             <v-col cols="4">
              <v-text-field :label="tLabel('lunghezza') + '*'" v-model.number="slotProps.data.lunghezza" :rules="[rules.required]" :error-messages="slotProps.errors.lunghezza" />
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('peso_max')"  v-model.number="slotProps.data.pesoMax" :error-messages="slotProps.errors.pesoMax" />
            </v-col>
            <v-col cols="4">
            <v-text-field :label="tLabel('posizione')" v-model.number="slotProps.data.posizioneBinario" :error-messages="slotProps.errors.posizioneBinario"/>
          </v-col>
        </v-row>
      </div>
    </template>
  </TosListVuetify>
  </div>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Binari",
  props: {
    parcoBinariId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      entityName: "binari",
      nomeParcoBinari: null,
      defaultFilters: {parcoBinariId: parseInt(this.parcoBinariId)},
      headers: [
        { text: "", value: "action" },
        { text: "Numero", value: "numero"},
        { text: "Descrizione", value: "descrizione" },
         { text: "Lunghezza", value: "lunghezza" },
        { text: "Peso Max", value: "pesoMax" },
        { text: "Posizione", value: "posizioneBinario" }       
      ],
    };
  },
  created() {
  },
  async mounted(){
    const parcoBinariResp = await this.$api.get(this.$apiConfiguration.BASE_PATH+"parcoBinari/"+this.parcoBinariId);
    this.nomeParcoBinari = parcoBinariResp.codice;
  },
  methods: {
    prepareItem(item) {
      item.parcoBinariId=this.parcoBinariId;
    },
  },
  components: {
    TosListVuetify: TosListVuetify,
  },
  mixins: [FormatsMixins]
};
</script>
